// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
*{
  padding: 0;
  margin: 0;
 box-sizing: border-box;
  /* font-family: "daxplinepro"; */
}

html, body {
  height: 100%;
  color: #ffffff;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,UAAU;EACV,SAAS;CACV,sBAAsB;EACrB,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["\n*{\n  padding: 0;\n  margin: 0;\n box-sizing: border-box;\n  /* font-family: \"daxplinepro\"; */\n}\n\nhtml, body {\n  height: 100%;\n  color: #ffffff;\n}\n\nbody {\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
