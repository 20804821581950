import React, { useState } from 'react';
import '../styles/Blog.css';
import blogHeroImg from '../Assets/Images/blog-hero-bg.svg';
import {blogData} from "../data2/mock";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import BlogContent from '../components/BlogContent';
import Navbar from '../components/Navbar';

const Blog = () => {
  const blogsPerPage = 6;
  const totalPages = Math.ceil(blogData.length / blogsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const displayedBlogs = blogData.slice(startIndex, endIndex);

  return (
    <>
    <Navbar/>
    <div className='blog_container'>
    <div className='blog_img_container'>
        <img src={blogHeroImg} alt='' />
      </div> 

      <BlogContent displayedBlogs={displayedBlogs} />

      <div className="pagination">
        <div className="arrow" onClick={handlePrev}>
          <BsArrowLeft style={{ width: '30px', height: '30px', color: '#8E8E8E' }} />
        </div>
        <div className="pageNumbers">
          {Array.from({ length: totalPages }).map((_, index) => {
            if (index < 3 || (index === totalPages - 1 && totalPages > 3) || (index >= currentPage - 1 && index <= currentPage + 1)) {
              return (
                <div
                  key={index + 1}
                  className={index + 1 === currentPage ? "currentPage" : "normalpage"}
                  onClick={() => setCurrentPage(index + 1)}
                >
                  <div >{index + 1}</div>
                </div>
              );
            } else if (index === 3 && totalPages > 3) {
              return <div key={index}>...</div>;
            }
            return null;
          })}
        </div>
        <div className="arrow" onClick={handleNext}>
          <BsArrowRight style={{ width: '30px', height: '30px', color: '#8E8E8E' }} />
        </div>
      </div>
    </div>
    </>
  );
};

export default Blog;
