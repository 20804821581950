import React from 'react';
import '../styles/Innovation.css';
import handsraised from '../Assets/Images/handraised.png';
import innogallery2 from '../Assets/Images/innovation-img-2.png';

const Innovation = () => {

  return (
    <div className='homeinnovation_main_container'>


        <h3 className='homecorevalues_headinmain'>
            Our core values
        </h3>

        <div className='homeinnovation_container'>
            <div className='homeinnovation_text_container'>
                <h3 className='homeinnovation_txth3main' >Innovation</h3>
                <p className='homeinnovation_txtpmain'>
                    Innovation motivates action: to take risks, encourage curiosity and new ideas,
                    learn from mistakes, and constantly strive to exceed expectations.
                    Through innovation, we generate solutions for our customers and raise the bar — both within our 
                    workplace and throughout our industry.
                </p>
            </div>
            <div className='homeinnovation_gallery_container'>
                <div className='homeinnovation_gallery1'>
                    <img src={handsraised} alt=" hands raised " className='homeinnovation_gallery1img' />
                </div>
                <div className='homeinnovation_gallery2'>
                    <img src={innogallery2} alt=" innovation gallery 2" className='homeinnovation_gallery2img' />
                </div>
            </div>

        </div>
    </div>
  )
}

export default Innovation;