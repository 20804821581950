import VatAfrica from "../Assets/Images/vatAfrica.png";
import Picture1 from "../Assets/Images/Picture1vatk.png";
import Picture2 from "../Assets/Images/Picture2vatk.png";
import Picture3 from "../Assets/Images/Picture3vatk.png";
import Picture4 from "../Assets/Images/Picture4vatk.png";
import Picture5 from "../Assets/Images/Picture5.png";
import Picture6 from "../Assets/Images/Picture6vatk.png";
import Picture7 from "../Assets/Images/Picture7.png";
import Picture8 from "../Assets/Images/Picture8vatk.png";


export const blogData = [
  {
    id: 1,
    VatAfrica: VatAfrica,
    DatePosted: "February 11, 2019",
    BlogHead:
      "Vatebra listed as one of the companies to inspire Africa in 2019 by the London Stock Exchange Group",
    prevText: "",
    NextText:
      "Tech For Security: Vatebra & UNDP lead the way with CEWERS Kaduna launch",
    blogText1:
      "Leading Pan-African Tech Solutions provider, Vatebra Limited has been listed as one of the Companies to Inspire Africa in 2019; by the London Stock Exchange Group.",
    blogText2:
      "According to the CEO of the London Stock Exchange Group, David Schwimmer, the report “identifies Africa’s most inspirational and dynamic private, high-growth companies to a global market.” He added that the companies listed in the report are crucial to the future of the African economy, capable of driving transformative economic growth in their home countries, Africa and beyond.",
    blogText3:
      "In his response to this recognition, the Managing Director of Vatebra Limited, Mr. Kunle Akinniran said “we feel very honored to be recognized by the prestigious London Stock Exchange Group. Vatebra has been at the fore of the technology evolution in Africa. We are behind some of the tech successes in Africa such as the development of customized solutions, mobile applications, identity solutions/biometrics, e-payment solutions, IGR solutions and other world-class solutions cutting across the private and public",
    blogText4:
      "This recognition complements other accomplishments of Vatebra Limited. Vatebra won the awards for the Technology Company of the Year in Nigeria in 2016, 2017 and 2018 organized by the Nigerian Technology Awards (NITA). Vatebra has also received other awards and recognitions from different bodies in Africa.All of these give us the motivation to keep pushing forward, knowing that we are adding value and making an impact to Africa” he added.",
    blogText5:
      "In order to be included in the list of Companies to Inspire Africa,  Vatebra met the stringent requirements of the London Stock Exchange group which included the strength of the business idea, demonstrating an excellent rate of growth, sustainable business model, the strength of Management Team and Scalability of the business.The report showcases outstanding stories of innovation, growth, and entrepreneurship in African businesses to a global audience. ",
    blogText6:
      "The Deputy Managing Director of Vatebra Limited,  Mr. Mike Aigbe also shared some of the future plans of the Company. He said,“Vatebra has begun expansion into more African countries like Uganda, Rwanda, Tanzania, and Ethiopia. The Company is also setting up a Technology Hub across various locations in Nigeria to further promote Technology advancement in the country; as well as support budding techpreneurs to become global businesses. The Hub will provide training, business incubation, and mentorship, and financing opportunities to start-ups,small and medium scale technology-driven businesses. This will further strengthen our position as the technology powerhouse in Africa” ",
    blogText7:
      "Vatebra was established in 2003 as Fleet Technologies and re-branded to Vatebra Limited in 2016, repositioning as the Tech backbone of Africa. Vatebra has businesses across Africa with offices in Lagos, Abuja, Accra, Freetown, and Nairobi. ",
  },
  {
    id: 2,
    VatAfrica: Picture1,
    DatePosted: "July 26, 2021",
    BlogHead:
      "Tech For Security: Vatebra & UNDP lead the way with CEWERS Kaduna launch",
    prevText:
      "Vatebra listed as one of the companies to  inspire Africa in 2019 by the London stock change group",
    NextText:
      "VATEBRA partners WAEC to reward best performing candidates, schools in 2020 WASSCE",
    blogText1:
      "Tech Company, Vatebra Limited has teamed up with the United Nations Development Program (UNDP) and the Kaduna State Peace Commission (KSPC) in the deployment of the CEWERS solution. CEWERS which means “Conflict Early Warning and Early Response System” is the innovative security-tech solution initiated by KSPC, sponsored by UNDP and developed by Vatebra Limited.",
    blogText2:
      "The CEWERS application allows people in conflict areas in and environs of Kaduna state to properly capture and channel feedbacks by enabling security monitoring groups and individual citizens to provide feedback on the progress of elections, security situation and crisis within the state, both positive and negative, to assure timely counter- response by appropriate authorities to avert violence or conflicts.",
    blogText3:
      "The CEWERS application developed by Vatebra Limited will facilitate the gathering, analysis and visualization of data with geo-tagging capabilities to represent the level and weighting of the risks, conflicts and responses. It is an integrated web, mobile app and SMS based system that is compatible with Android devices and tablets, and can be downloadable through play store.",
    blogText4:
      "Speaking at the launch of the CEWERS application; Kenneth Omale a Key Business Manager at Vatebra Limited gave an executive insight of CEWERS and its benefits; which includes: holistic view of Incident map of Kaduna State, engage communities to report early, warning signals, Initiate Timely Response to reported warning signals, control fake news spread, Band generate data for informed decisions and policy making.",
    blogText5:
      "Furthermore, the Regional Head, Abuja Branch, Vatebra Ltd, Elsie Njoku spoke about the CEWERS governance structure. She explained that CEWERS will be an avenue for the general public who will pose as anonymous to escalate incident. She gave insights on the roles of the various stakeholders in crises reporting and management. ",
    blogText6:
      "Participants at the launch included the Executive Governor of Kaduna State Mallam Nasir El-Rufai, Deputy Governor of Kaduna State Hadiza Balarabe, Vice Chairman, Kaduna Peace Commission, Priscilla Ankut, representatives of the United Nations Development Program (UNDP), Embassy of Norway, Director Plateau State Peace Building Agency, Commissioner of Police, Kaduna State, Secretary to Kaduna State Government, representative of Civil Society Organizations, representative of traditional leaders and religious leaders.",
  },
  {
    id: 3,
    VatAfrica: Picture2,
    DatePosted: "November 24, 2021",
    BlogHead:
      "VATEBRA partners WAEC to reward best performing candidates, schools in 2020 WASSCE",
    prevText:
      "Tech For Security: Vatebra & UNDP lead the way with CEWERS Kaduna launch",
    NextText:
      "Tech investment: Vatebra Internship & Mentorship Program Launches",
    blogText1:
      "Information and Communications Technology company, Vatebra Limited, in partnership with the West African Examinations Council, WAEC, Nigeria has once again given out awards to best performing schools and candidates in the West African Secondary School Certificate Examinations, WASSCE, for the 2020 academic year..",
    blogText2:
      "The WAEC/VATEBRA Merit Awards, which took place in Ibadan, Oyo State is part of a range of Corporate Social Responsibility projects aimed at giving back to the public, particularly in the academic domain. The awards were presented to the various winners at the formal opening ceremony of the 59th annual meeting of the Nigeria National Committee (NNC) held at the Daylan Event Center, Ibadan on November 18, 2021.",
    blogText3:
      "Speaking on the awards at the event, the Head, Educational Bodies at Vatebra Limited, Oluwole Aduloju posited that “one major criterion to be met by winners of the WAEC/VATEBRA merit award is to be outstanding and be without blemish knowing that this driving principle also transcends from one of our organizational core values of Excellence,”",
    blogText4:
      "On the other hand, the Head of National Office, West African Examinations Council, Mr. Patrick Areghan, in his remarks gave a brief background of the awards and the milestones achieved by the foremost Examinations body in recent years. The event was well attended by dignitaries including and the Honorable Commissioner for Basic Education in Oyo State, Barrister Rahman Abiodun Abdu-Raheem and other delegates from the Oyo State government.",
    blogText5:
      "The awards instituted and presented this year are based on the following categories: the best public secondary school in sciences: (Chemistry, Biology and Physics) in Nigeria; the overall best public secondary school in all subjects in Nigeria; the best overall female candidate in public secondary schools in all subjects in Nigeria and lastly, the best overall male candidate in public secondary schools in all subjects in Nigeria.",
    blogText6:
      "Winners of the awards were presented with various cash prizes. Ayebode High School, Ayebode, Ekiti State awarded winner of the WAEC/VATEBRA Merit Award for best public secondary School in Sciences: (CHEMISTRY, BIOLOGY and PHYSICS) in Nigeria was presented with a cash prize of 1.5 million Naira. Community Secondary School, Abajah, Owerri. Imo State awarded WINNER of the WAEC/VATEBRA Merit Award for Overall Best Public Secondary School in all subjects in Nigeria got prize money of 2.5 million Naira.",
  },
  {
    id: 4,
    VatAfrica: Picture3,
    DatePosted: "April 26, 2022",
    BlogHead:
      "Tech investment: Vatebra Internship & Mentorship Program Launches",
    prevText:
      "VATEBRA partners WAEC to reward best performing candidates, schools in 2020 WASSCE",
    NextText: "Again, Vatebra wins tech company of the year award",
    blogText1:
      "Leading tech company Vatebra Limited has announced the commencement of her first streamlined internship program, this was made known in an event held at the Vatebra Tech Hub in Lagos. The program entitled the Vatebra Internship and Mentorship Program #VIM2022 is designed to develop young and enthusiastic individuals into a unique learning and growth system with Vatebra Limited.",
    blogText2:
      "The program which kicked off officially on the 22nd of April 2022 with its first cohort of interns and mentees, comes at a time when the tech ecosystem is rapidly in search of solutions, people and capabilities to diversify the planets pipeline. While useful and ingenious technology professionals are increasingly becoming scarce as the industry generally witnesses a brain drain, it became paramount that upskilling and apprenticeship will go a long way to start building the technology professionals of the future. ",
    blogText3:
      "According to the Program Director Mosunmola Adewale-Akobada “The VIM program functions much like apprenticeship, providing hands-on technical training and capstone projects, as well as mentorship. As with the organization the program is both physical and virtual. It is scheduled to last for 10 months”.",
    blogText4:
      "The Head of African Markets, at Vatebra Limited, Matthew Omoaka, posit: “This internship gives participants the rare opportunity to acquire skills and experience through short-term work rather than a more traditional training approach. It gives people a chance to develop some really cool experience in a short amount of time and really very specific, very marketable information to put on a resume. ",
    blogText5:
      "He added, “conferring to the program plan, the interns/mentees require up to 40 hours of work every week for a minimum of 10 months. Working hours are coordinated with the Company supervisor. The interns/mentees must demonstrate high level performance and will be evaluated monthly. At the middle and end of the program the Intern/Mentees will make presentations and reports that will be used as a basis to judge their participation and achievements”.",
    blogText6:
      "Speaking through a live Zoom call, The Deputy Managing Director of Vatebra Limited Mr. Mike Aigbe admonishes the interns ‘that the Interns program is an opportunity which has been provided at no cost to them and for those who wouldn’t necessarily have had it. It will also help the beneficiaries in their career path not only by getting the experience but as a concept of being able to balance that with the right exposure” ",
    blogText7:
      "Vatebra Limited is an ICT company in Africa with competency in the delivery of cutting-edge Software Solutions in the Private and Public Sectors. Vatebra was established in 2003 as Fleet Technologies Limited, but rebranded in 2016 as Vatebra Limited. The Company is established to bridge the gap in the provision of competent and dependable Software Solutions. With this initiative, Vatebra Limited has continued to show obligation to the development of technology in Africa. ",
    blogText8: "The Beneficiaries ",
    blogText9:
      "John Eke is a proactive leader and lover of technology, he enjoys collaborating with amazing teams building great products that solve customer problems and drive real business value. He started his career in technology as a frontend developer writing Html, CSS, JavaScript and ReactJS. Although he loves learning how products are engineered and built, he developed a passion for validating product ideas and conceptualizing design/user experience and transitioned to product design. He truly believes in the intersection of technology, business and user experience in creating great products that provide customer satisfaction and real business value. His mission is to create great technology products that will be of impact to millions of people & businesses across various sectors and his greatest values are optimism, honesty, determination and resilience. When he's not thinking of products, you can catch him reading non-fiction books, playing chess or hanging out with friends.",
    blogText10:
      "Ajayi Oluwapamilerin, a graduate of Geology from the University of Ilorin year 2019. He is a the first from a Family of 5 and a native of Osun State. Pamilerin also served as the general secretary of IAP project, Ilorin a project that was created to educate young ones on the usefulness of education. He believes humans should be versatile and not staying static, this had been his motivation on his journey to become a software developer. Pamilerin aims to be the best on his Software Development endeavor, believing it won’t be easy but he's willing to give in his best.",
    blogText11:
      "Barnabas Oyarero is a 2020 Computer Science graduate of Edo University lyamho. He concluded his National Youth Service program on February 2022. Barnabas is hardworking, inquisitive and truly interested in not only building his career but also in making a sizable amount of impact in any and everything he does. He believes in his ability in generating interesting ideas as well as being open to those pitched from others. Barnabas enjoys to collaborate with others because this motivates him to work more efficiently and learn interesting perspectives and angles from those that he works with. Asides his goal of growing his career, he has other interests. He is a cinephile who truly enjoys films and the art of filmmaking and even has hopes of one day writing and directing his own films. He is usually inspired by places, family, friends and just people in general.",
  },
  {
    id: 5,
    VatAfrica: Picture4,
    DatePosted: "07 December 2017",
    BlogHead: "Again, Vatebra wins tech company of the year award",
    prevText:
      "Tech investment: Vatebra Internship & Mentorship Program Launches",
    NextText:
      "CSR: Vatebra launches cerebral palsy initiative, donates items to Lagos center",
    blogText1:
      "Leading pan-African tech solutions provider, Vatebra Limited, has won the award for the Technology Company of the Year for 2017.The announcement was made at the Nigerian Technology Awards Ceremony held in Lagos.",
    blogText2:
      "In winning the most coveted award of the night, Vatebra topped the list of nominees in a category with some industry big names. Having bagged the same award in 2016, Vatebra has concretized its position as the backbone of Information Technology in Africa.",
    blogText3:
      "Nigeria Technology Awards (NiTA) is an award organized to recognize, celebrate and reward Technology Entrepreneurs, Innovators, Academicians, Inventors and policy makers (Government) in Nigeria. The Awards as projected by the organizers is aimed at building a large scale and internationally recognized Technology Awards in Nigeria.",
    blogText4:
      "By rewarding professional expertise in the technology space, the organizers of this worthy cause have in many ways helped to build a culture that will in return promote innovation, healthy competition and improve service delivery.",
    blogText5:
      "Vatebra’s Managing Director, Kunle Akinniran, who received the award on behalf of the company said, “Vatebra is honoured to receive this great award; and in a special way we would like to thank our customers, partners, policy makers and other stakeholders who challenge us to do better every time, thereby fostering our drive for excellence”.",
    blogText6:
      "In expressing his appreciation for the award, the Deputy Managing Director, Vatebra Limited, Mike Aigbe said, “Since inception in 2003, Vatebra has had a history of revolutionizing the way things have been done in Africa. Innovation has been our focus and we try to portray this in everything we do. In line with our vision, we aim to be the foremost provider of Information Technology solutions in Africa.” ",
    blogText7:
      "Vatebra is behind some of the critically acclaimed tech successes in Africa such as the Deployment of Customized Solutions, Mobile applications, Identity Solutions/Biometrics, e-payment solutions and many others. The Company was established in 2003 as Fleet Technologies Limited in order to bridge the gap in the provision of competent and dependable Software Solutions in Nigeria and Africa. “Vatebra has presence in Lagos, Abuja, Accra, Freetown and Nairobi. ",
  },
  {
    id: 6,
    VatAfrica: Picture5,
    DatePosted: "June 22, 2022",
    BlogHead:
      "CSR: Vatebra launches cerebral palsy initiative, donates items to Lagos center",
    prevText: "Again, Vatebra wins tech company of the year award",
    NextText:
      "Nigeria’s Vatebra Tech Hub opens applications for incubation program",
    blogText1:
      "IN continuation of its yearly Corporate Social Responsibility (CSR) drive, leading Nigerian TechCo, Vatebra Limited, has drawn support and awareness for cerebral palsy (CP) with the Light-up-for-Palsy initiative.",
    blogText2:
      "The initiative being a philanthropic endeavor, mobilized her workforce and resources towards creating awareness and donating to support children living with cerebral palsy. The Light-up for Palsy initiative being a non-for-profit charity endeavor, aimed at children living with the very rare congenital abnormality.",
    blogText3:
      "Speaking on the initiative, Vatebra’s Head of Brand and Communications, Ben Iyoha said the support for the Cerebral Palsy Centre is in alignment with the company’s CSR goals.",
    blogText4:
      "According to him; “The motivation behind mobilization of our staff to support this noble cause is to leverage our CSR platform which promotes the culture of giving back to communities in which we operate. By so doing, we creatively provided support items for kids of the Cerebral Palsy Centre, Lagos”.",
    blogText5:
      "Giving further insight on the CSR drive, Matthew Omoaka, Head of Vatebra’s African Market Department said, “In implementation of the year’s participatory CSR initiative employees of the company were encouraged to voluntarily donate and proceeds donated are hugely supported by the organizational corporate social responsibility fund. ",
    blogText6:
      "He noted that the existing giveback culture of Vatebra Limited has helped immensely to impact lives positively in the last five years. “We are very assured that this collaboration with the Cerebral Palsy Centre will further contribute to provide support to the underserved children with Cerebral Palsy in our society,” he said. ",
    blogText7:
      "Founder and Executive Director, Cerebral Palsy Centre, Lagos, Nonye Nweke explained how the centre came to be 15 years ago when her daughter Chizimuzondu was adopted as a six-day-old baby. ",
    blogText8:
      "“At five months, Chizimuzondu was diagnosed with Cerebral Palsy (CP) at Owerri Sick Bay, a private children’s hospital in Owerri, South East Nigeria. Coincidentally, Chizimuzondu was adopted from Owerri through the ministry of women development and social welfare. The doctor, the owner of the hospital, advised that I take back the child to the ministry. He asserted that I would be unable to care for such a child. And this was how I started the journey,” she explained.",
    blogText9:
      "The Vatebra team also was taken on a tour of the newly built Cerebral Centre at the Ibeju-Lekki site, Lakowe as the team also met with some of the amazing kids pressing day-after-day at the first completed special care ward. Vatebra afterwards handed over to the centre items on their request list, which included 2HP inverter linear air-conditioner, Diapers of various sizes, baby wipes, Syrups, Tablets, Cod liver oils etc.",
  },
  {
    id: 7,
    VatAfrica: Picture6,
    DatePosted: "DECEMBER 13, 2019",
    BlogHead:
      "Nigeria’s Vatebra Tech Hub opens applications for incubation program",
    prevText:
      "CSR: Vatebra launches cerebral palsy initiative, donates items to Lagos center",
    NextText:
      "Vatebra Tech Hub Launches Idea Lab Program as a Follow Up to its Pitch Event",
    blogText1:
      "The Lagos-based Vatebra Tech Hub has opened applications for its first six-month incubation program, which will provide startups which the resources and support they need to get product-market fit.",
    blogText2:
      "Disrupt Africa reported in September Nigeria’s Bank of Industry had launched Tech Hub Ajah Lagos, a corporate social responsibility (CSR) initiative that aims to promote skill development, youth entrepreneurship and job creation in new technology and innovations.",
    blogText3:
      "The hub is now seeking startups that have developed a minimum viable product (MVP) that solves a major problem to apply for its first incubation program, which will be mentorship-driven and includes core modules on topics such as customer development, business strategy, legal, and product development.",
    blogText4:
      "Working with partners including Amazon Web Services (AWS), HubSpot and Ringcaptcha, the program offers benefits including shared office space, business advisory support, access to funding from the Bank of Industry, US$10,000 in AWS credits, office hours with mentors, and access to a wide network of investors.",
    blogText5:
      "Applications are open here until January 3, 2020, and will be reviewed on an ongoing basis. The incubation program is scheduled to run from January 20 until July 24.",
  },
  {
    id: 8,
    VatAfrica: Picture7,
    DatePosted: "March 9, 2022",
    BlogHead:
      "Vatebra Tech Hub Launches Idea Lab Program as a Follow Up to its Pitch Event",
    prevText:
      "Nigeria’s Vatebra Tech Hub opens applications for incubation program",
    NextText:
      "Vatebra Academy discusses the future of tech with CODIFY tech meetup",
    blogText1:
      "The Lagos-based Vatebra Tech Hub has opened applications for its first six-month incubation program, which will provide startups which the resources and support they need to get product-market fit.",
    blogText2:
      "Disrupt Africa reported in September Nigeria’s Bank of Industry had launched Tech Hub Ajah Lagos, a corporate social responsibility (CSR) initiative that aims to promote skill development, youth entrepreneurship and job creation in new technology and innovations.",
    blogText3:
      "The hub is now seeking startups that have developed a minimum viable product (MVP) that solves a major problem to apply for its first incubation program, which will be mentorship-driven and includes core modules on topics such as customer development, business strategy, legal, and product development.",
    blogText4:
      "Working with partners including Amazon Web Services (AWS), HubSpot and Ringcaptcha, the program offers benefits including shared office space, business advisory support, access to funding from the Bank of Industry, US$10,000 in AWS credits, office hours with mentors, and access to a wide network of investors.",
    blogText5:
      "Applications are open here until January 3, 2020, and will be reviewed on an ongoing basis. The incubation program is scheduled to run from January 20 until July 24. ",
    blogText6:
      "Vatebra Tech Hub Launches Idea Lab Program as a Follow Up to its Pitch Event ",
    blogText7:
      "Vatebra Tech Hub is an innovation hub funded by the Bank of Industry to support startups and technology development in Nigeria. The Hub was officially launched by the Governor of Lagos state, His Excellency Babajide Sanwo-olu in September 2019 represented by the Commissioner for Science and Technology Hakeem Fam. ",
    blogText8:
      "Vatebra tech Hub partnership with Bank of Industry has set up innovation hubs across 4 geopolitical zones and since the launch of the inaugural hub in Lagos, the partnership have led to the set of Bayelsa tech Hub, Bank of industry – University of Africa, Toru-Orua Incubation center (BOI- UAT),Kebbi Innovation hub and Kada hive innovation and tech hub located in Kaduna.",
    blogText9:
      "Since the launch of the hub, we have partnered with organizations like Agora, Lagos Innovates Lenco, AWS, Hubspot, Embassy of Finland to support startups in our network. ",
    blogText10:
      "As a way to continue to provide mentorship  and  funding linkages for startups in our community we recently partnered with the University of Wisconsin Madison Law School & PUNUKA Attorneys & Solicitors to organize a  Pitch Event.",
    blogText11: "The featured Startups included:",
    blogText12:
      "-Guava - Guava is building a payment that helps wholesalers and manufacturers improve sales and increase cash flow by offering their business customer credit at the point of purchases so that they can buy now and pay later. (BNPL)",
    blogText13:
      "-LearnAli - Learnali provides an on-demand online skill acquisition and talent development platform for students and graduates to acquire the skills needed to increase their employability, and land their dream job.",
    blogText14:
      "-KoinWa - KoinWa is a cross border peer to peer trading platform where you can exchange Bitcoin for your local currency and vice versa. KoinWa makes it safe and easy to buy, sell and store cryptocurrencies in Nigeria",
    blogText15:
      "-AvivWallet - Aviv allows individuals and businesses to easily become mobile money agents from the comfort of their homes and offices and matches them with users who need instant and affordable cash. Providing a better alternative to the POS and ATM systems without any of their limitations and accelerating financial inclusion in Africa.",
    blogText16:
      "-Earlybrite - Earlybrite is a revolutionary and innovative Edtech ecosystem/platform designed on breeding innovative solutions to upskill the knowledge base of the next generation through shared learning and hands-on activities.",
    blogText17:
      "-Gasmonkey - Gasmonkey is providing access to cheaper and more efficient energy sources for domestic purposes. They also build smart gas cylinders that tell the fuel quantity in it and how long it will last.",
    blogText18:
      "The founders pitched to a panel of U.S - based Angel Investors, who were also joined by representatives from PUNUKA Attorneys & Solicitors who presented on Legal Issues related to International Angel Investment.",
    blogText19:
      "As a followup to the pitch event, in order to continue to provide mentorship for startups in our community Vatebra Tech hub, in Partnership with all the hubs powered by Bank Of Industry launched Idea Lab.",
    blogText20:
      "The Idea Lab program which is for a duration of 9-weeks is designed to give insights on how start-ups work, and for participants to learn what determines start-up success and to get the chance to start working on world-changing ideas.",
    blogText21:
      "Participants must have an idea or concept that they believe can develop into a transformative business that will leverage technology to drive significant impact in Nigeria. University students, young graduates and unemployed youth or anyone that has an idea to test are encouraged to apply.",
    blogText22:
      "The Program focus seeks to answer the important questions, I have an idea but is it a business? Experienced entrepreneurs and business professionals will serve as the Mentor for each team/individual assisting them in defining feasibility, desirability & viability of their early-stage idea.",
    blogText23:
      "We are looking for entrepreneurs with Passion, Commitment and Curiosity.",
    blogText24:
      "The curriculum will have elements around Idea formation by actively engaging in research, receiving expert advice & insight from subject matter experts, and identifying the resources required to turn the concept into a viable product.",
    blogText25:
      "During the program, selected teams/individuals will have access to Shared Office,",
    blogText26:
      "Hand on Business advisory support, AWS Credits, up to 10,000 USD, Agora Tools (Up to 1,000,000 free minutes) and more",
    blogText27:
      "The program mode of delivery is hybrid ( Virtual/In-person). Selected entrepreneurs will have access to the coworking space of all the implementing partners during the program.",
    blogText28: "•	The Application Deadline is March 21st, 2022.",
    blogText29: "•	The program Launch date is March 28th, 2022.",
    blogText30:
      "The Vatebra Tech Hub is committed to promoting skills development, youth entrepreneurship, and job creation in new technology and innovations.Participating hubs Include Bayelsa Tech Hub, Kebbi Innovation & Tech Hub, Kada Hive and BOI UAT.",
      linkText: "Interested participants can apply here:",
      linkUrl: "https://bit.ly/startuplabbyvatebra",
  },
  {
    id: 9,
    VatAfrica: Picture8,
    DatePosted: "March 29, 2018",
    BlogHead:
      "Vatebra Academy discusses the future of tech with CODIFY tech meetup",
    prevText:
      "Vatebra Tech Hub Launches Idea Lab Program as a Follow Up to its Pitch Event",
    NextText: "",
    blogText1:
      "The demand for coding skills is skyrocketing, and not just for developers; programming is playing a bigger role in every career path, It is for this reason that the first edition of CODIFY tech meetup happened in Lagos. Technological innovations in Nigeria and perhaps Africa in recent times have burgeoned in leaps and bounds and it is in furtherance of this growth that tech enthusiast and players in the sector gathered at the first edition of “CODIFY – Tech Meetup” to discuss the future of tech as it relates to Africa.",
    blogText2:
      "Like meetups obtainable in other climes, CODIFY aimed at narrowing the knowledge gap by preaching, permeating and predicting the future of man which will be rooted in great codes. Some of the determinations for the organizers of CODIFY was for people to meetup in smaller, interest-driven groups, to share, learn and grow together, to help youths focus on the right technical skills so they can pursue a more fulfilling career.",
    blogText3:
      "Topics critically discussed and analyzed at the sessions of the maiden edition of CODIFY included Artificial Intelligence & Advanced Machine Learning, Intelligent Apps, Intelligent Things, and Virtual & Augmented Reality, Digital Twin, Mesh App and Service Architecture.",
    blogText4:
      "In shedding light to how these technologies can shape our world Soji Egbonwon of CHAMS Limited, Ody Mbegbu of Cyberspace, Bola of Eminent Software’s and Evans Okosodo of Vatebra Limited all gave detailed presentations of the futuristic leanings from the programming point of view.",
    blogText5:
      "In his opening discourse, the MD/CEO of Vatebra Limited Mr. Kunle Akinniran enjoined Participants of the meet –up to lead from the front as the technology industry is in need of brilliant youths with out-of-the box ideas, as it is with such ideas that Africa can be easily transformed.",
    blogText6:
      "At the peak of the event, graduates of Vatebra Academy received certificates of achievement upon completion of study at the Academy which was presented to them by the Deputy Managing Director of Vatebra Limited, Mr. Mike Aigbe. ",
    blogText7:
      "The CODIFY initiative is a creation of Vatebra Academy, the academy was set up to provide a bridge to the technical skill gap in the areas of innovation technologies. It is also positioned to revolutionize the IT industry by providing first class pre and post-graduate technology-training which will have a positive impact in the Information Technology space and the business environment in Sub Saharan Africa.",
    blogText8:
      "Vatebra Academy teaches students several in-demand programming languages. Students learn to code through real-world, hands-on software development training. Vatebra Academy graduates are well-rounded, full-stack, medium-level software developers that can code on the front-end and back-end. The academy offers courses in Building Scalable Web Applications, Mobile App Development (Android & iOS) and Software Architecture.",
  },
  
  
  
];
