// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy_policy_container{
background: linear-gradient(180deg, #EAAD11 3.71%, #EAAD11 45.69%);
 padding: 40px 50px 50px 50px;
 z-index: 3;
}

.policy_row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    padding-top: 30px ;
}

.policy_text2{
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    width: 100%;
    text-align: left;
    /* height: 280px; */  
}

.privacypolicytext1{
font-weight:800;
font-size: 16px;
line-height: 24.46px;
color: #005B00;
}

`, "",{"version":3,"sources":["webpack://./src/styles/PrivacyPolicy.css"],"names":[],"mappings":"AAAA;AACA,kEAAkE;CACjE,4BAA4B;CAC5B,UAAU;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;AACA,eAAe;AACf,eAAe;AACf,oBAAoB;AACpB,cAAc;AACd","sourcesContent":[".privacy_policy_container{\nbackground: linear-gradient(180deg, #EAAD11 3.71%, #EAAD11 45.69%);\n padding: 40px 50px 50px 50px;\n z-index: 3;\n}\n\n.policy_row{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    margin: auto;\n    padding-top: 30px ;\n}\n\n.policy_text2{\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 35px;\n    width: 100%;\n    text-align: left;\n    /* height: 280px; */  \n}\n\n.privacypolicytext1{\nfont-weight:800;\nfont-size: 16px;\nline-height: 24.46px;\ncolor: #005B00;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
